@font-face {
    font-family: poppins-regular;
    src: url(/assets/fonts/Poppins-Regular.ttf);
}
@font-face {
    font-family: poppins-medium;
    src: url(/assets/fonts/Poppins-Medium.ttf);
}
@font-face {
    font-family: poppins-semibold;
    src: url(/assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
    font-family: poppins-bold;
    src: url(/assets/fonts/Poppins-Bold.ttf);
}
@font-face {
    font-family: IBMPlexSans-Medium;
    src: url(/assets/fonts/IBMPlexSans-Medium.ttf);
}
@font-face {
    font-family: IBMPlexSans-Bold;
    src: url(/assets/fonts/IBMPlexSans-Bold.ttf);
}
@font-face {
    font-family: patrickhandsc-regular;
    src: url(/assets/fonts/PatrickHandSC-Regular.ttf);
}
@font-face {
    font-family: poorstory-regular;
    src: url(/assets/fonts/PoorStory-Regular.ttf);
}
@font-face {
    font-family: fontAwesome;
    src: url(/assets/fonts/FontAwesome.otf);
}
@font-face {
    font-family: 'summernote';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(/assets/fonts/summernote.eot);
    src: url(/assets/fonts/summernote.eot?#iefix) format('embedded-opentype'), url(/assets/fonts/summernote.woff2) format('woff2'), url(/assets/fonts/summernote.woff) format('woff'), url(/assets/fonts/summernote.ttf) format('truetype');
}
